import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import('@/components/settings/main.vue'),
      meta: {
        pageTitle: 'CMS',
        breadcrumb: [
          {
            text: 'CMS',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-team',
      name: 'addTeam',
      component: () => import('@/components/settings/add-team-member.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/accept-team-invite',
      name: 'acceptTeamInvite',
      component: () => import('@/components/settings/accept-team-invite.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/groups',
      name: 'groups',
      component: () => import('@/views/Groups/Groups.vue'),
      meta: {
        pageTitle: 'Groups',
        breadcrumb: [
          {
            text: 'Groups',
            active: true,
          },
        ],
      },
    },
    {
      path: '/groups/:id',
      name: 'groups',
      component: () => import('@/views/Groups/ManageGroups.vue'),
      meta: {
        pageTitle: 'Manage Group',
        breadcrumb: [
          {
            text: 'Manage Group',
            active: true,
          },
        ],
      },
    },
    {
      path: '/groups/old',
      name: 'groups',
      component: () => import('@/views/old_Groups.vue'),
      meta: {
        pageTitle: 'Groups',
        breadcrumb: [
          {
            text: 'Groups',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gamification',
      name: 'gamification',
      component: () => import('@/views/Gamification/Gamification.vue'),
      meta: {
        pageTitle: 'Gamification',
        breadcrumb: [
          {
            text: 'Gamification',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gamification/:id',
      name: 'gamification',
      component: () => import('@/views/Gamification/Gamification.vue'),
      meta: {
        pageTitle: 'Gamification',
        breadcrumb: [
          {
            text: 'Gamification',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gamifications/old',
      name: 'gamification',
      component: () => import('@/views/Gamification.vue'),
      meta: {
        pageTitle: 'Gamification',
        breadcrumb: [
          {
            text: 'Gamification',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages',
      name: 'packages',
      component: () => import('@/views/Packages/Packages.vue'),
      meta: {
        pageTitle: 'Packages',
        breadcrumb: [
          {
            text: 'Packages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages/old',
      name: 'packages',
      component: () => import('@/views/Packages.vue'),
      meta: {
        pageTitle: 'Packages',
        breadcrumb: [
          {
            text: 'Packages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/employee',
      name: 'addEmployee',
      component: () => import('@/views/Employee/Index.vue'),
      meta: {
        pageTitle: 'Employees',
        breadcrumb: [
          {
            text: 'Employees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/employee/old',
      name: 'addEmployee',
      component: () => import('@/views/AddEmployee.vue'),
      meta: {
        pageTitle: 'Employees',
        breadcrumb: [
          {
            text: 'Employees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/Activities/index.vue'),
      meta: {
        pageTitle: 'Activity Mangament',
        breadcrumb: [
          {
            text: 'Activity Schedule',
            active: true,
          },
        ],
      },
    },
    {
      path: '/attendance-management',
      name: 'attendanceManagement',
      component: () => import('@/views/AttendanceManagement/Index.vue'),
      meta: {
        pageTitle: 'Attendance Management',
        breadcrumb: [
          {
            text: 'Attendance Management',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity/:id',
      name: 'attendanceManagement',
      component: () => import('@/views/AttendanceManagement/Activity.vue'),
      meta: {
        pageTitle: 'Activity',
        breadcrumb: [
          {
            text: 'Attendance Management',
            active: true,
          },
        ],
      },
    },
    // ActivityAttendee
    {
      path: '/view-attendees/:id',
      name: 'attendanceManagement',
      component: () => import('@/views/AttendanceManagement/ViewAttendees.vue'),
      meta: {
        pageTitle: 'View Attendees',
        breadcrumb: [
          {
            text: 'View Attendees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity-attendees/:id',
      name: 'ActivityAttendee',
      component: () => import('@/views/Activities/components/ActivityAttendees.vue'),
      meta: {
        pageTitle: 'View Attendees',
        breadcrumb: [
          {
            text: 'View Attendees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/style',
      name: 'StyleGuide',
      component: () => import('@/views/StyleGuide.vue'),
      meta: {
        pageTitle: 'Style Guide',
        breadcrumb: [
          {
            text: 'Style Guide',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms',
      name: 'CMS',
      component: () => import('@/views/cms.vue'),
      meta: {
        pageTitle: 'CMS',
        breadcrumb: [
          {
            text: 'CMS',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/complete-profile-page',
      name: 'CompleteProfilePage',
      component: () => import('@/components/user-profile/complete-user-profile.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/community-selection',
      name: 'CommunitySelection',
      component: () => import('@/components/community/community-selection.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/aws-cognito-social',
      name: 'SignupComponentSocialCognito',
      component: () => import('@/views/AwsCognitoSocial.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signup',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup/SignupComponent.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signup',
      },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/components/forgot-password/forgot-password.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signup',
      },
    },
    {
      path: '/create-community',
      name: 'CreateCommunity',
      component: () => import('@/components/community/community-registration.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signup',
      },
    },
    {
      path: '/signup-social',
      name: 'SignupComponentSocial',
      component: () => import('@/views/Signup/SignupComponent.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signup',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  const unProtectedRoutes = [
    'login',
    'signup',
    'SignupComponentSocialCognito',
    'SignupComponentSocial',
    'ForgotPassword',
  ]

  const isLoggedIn = localStorage.getItem('token')
  const loginToken = localStorage.getItem('loginToken')

  if (!unProtectedRoutes.includes(to.name) && !isLoggedIn) {
    return next({ name: 'login' })
  }
  if (to.name === 'CommunitySelection') {
    localStorage.setItem('token', loginToken)
  }
  // if (
  //   (!isValidTokens || !userData)
  //   && !beforeAccountsSwitching.includes(to.name)
  // ) {
  //   return next({ name: 'CommunitySelection' })
  // }
  next()
})

export default router
